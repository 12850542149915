import { Component } from "vue";
import DefaultComponent from "./DefaultLayoutComponent.vue";

export default class LayoutManager {
  private static domainMap: { [domain: string]: Component } = {};
  private static default: Component | null = null;

  static register(domain: string, layoutComponent: Component): void {
    LayoutManager.domainMap[domain] = layoutComponent;
  }

  static defaultComponent(comp: Component | null = null): Component {
    if (comp) {
      LayoutManager.default = comp;
      return comp;
    }

    if (null != LayoutManager.default) {
      return LayoutManager.default;
    }

    return DefaultComponent;
  }

  static getLayout(domain: string): Component {
    return LayoutManager.domainMap[domain] || LayoutManager.defaultComponent();
  }
}

<template>
  <AppLayout>
    <HeadlessListing
      :key="route.params.slug"
      :listing-slug="route.params.slug"
      @thanksClosed="bidQuantity = 0"
    >
      <template #default="{ onBid, listing, countdownTo, reloadListing }">
        <div class="md:flex max-w-[1800px] mx-auto">
          <div
            class="md:w-1/2 md:block flex-grow-0 flex h-full items-stretch pt-4 md:pt-24 max-w-screen-sm"
          >
            <div
              class="border-2 rounded-xl overflow-hidden border-boba-grey bg-white order-0 flex-grow text-center p-2"
            >
              <img
                class="mx-auto max-h-[50vh]"
                :src="currentImage || listing.images[0]"
                :alt="`${listing.title} Product Image`"
                @click="openDialog(currentImage || listing.images[0])"
              />
            </div>
            <image-carousel
              v-if="listing.images.length > 1"
              :images="listing.images"
              @choose="chooseImage"
            />
          </div>

          <dialog
            class="bg-transparent max-w-[95vw] max-h-[80vh] md:-top-[10vh] overflow-hidden"
          >
            <button
              class="absolute aspect-square rounded-lg top-2 right-2 px-3 py-2 shadow bg-white flex justify-center items-center"
              @click="closeDialog"
            >
              <i class="fa-solid fa-xmark text-boba-grey text-lg" />
            </button>
            <div
              class="bg-white border rounded-lg w-full md:h-full overflow-clip text-center"
            >
              <img
                :src="imageDetail"
                :alt="`${listing.title} Product Image`"
                class="w-auto max-h-[80vh] mx-auto object-cover"
              />
            </div>
          </dialog>

          <div class="md:pt-24 md:ml-8 flex-grow">
            <div class="pt-4 pb-6 bg-boba-sand -mx-4 px-4">
              <h1
                v-if="listing.subtitle"
                class="uppercase tracking-tighter font-medium leading-3"
              >
                {{ listing.subtitle }}
              </h1>
              <h2 class="text-4xl tracking-tighter leading-tight">
                {{ listing.title }}
              </h2>
            </div>
            <div
              class="bg-boba-grey rounded-lg md:py-6 py-4 pb-4 px-6 md:px-8 mt-4"
            >
              <div class="relative">
                <div class="">
                  <div class="flex justify-between items-end md:block">
                    <p class="md:text-3xl md:font-base text-xl tracking-tight">
                      <span v-if="listing.current_price"> Current Price </span>
                      <span
                        v-else-if="
                          DateTime.fromISO(listing.starts_at) > DateTime.now()
                        "
                        class="text-boba-grey-dark"
                      >
                        Not available yet
                      </span>
                      <span v-else class=""> Auction Ended </span>
                    </p>
                    <scoreboard-countdown
                      class="absolute right-0 top-0"
                      :to="countdownTo"
                      @ended="callIfNotEnded(listing, reloadListing)"
                      :key="countdownTo"
                    >
                      <template
                        v-if="
                          !listing.current_price &&
                          DateTime.fromISO(listing.starts_at) > DateTime.now()
                        "
                        #label
                      >
                        <p
                          class="md:flex md:items-center md:justify-start md:space-x-2 space-x-2"
                        >
                          <span class="md:pb-2 md:inline-block"
                            ><i class="fa-solid fa-clock text-base md:text-xl"
                          /></span>
                          <span>Begins in</span>
                        </p>
                      </template>
                    </scoreboard-countdown>
                  </div>
                  <p
                    v-if="listing.current_price"
                    class="md:mt-4 md:text-6xl text-4xl font-semibold tracking-tighter"
                  >
                    <span class="font-semibold">
                      {{ $format(listing.current_price.price / 1e9) }}
                    </span>
                    <span
                      class="font-normal md:text-3xl text-xl tracking-tight"
                    >
                      miles/ea.</span
                    >
                  </p>
                  <login
                    v-else-if="
                      DateTime.fromISO(listing.starts_at) > DateTime.now()
                    "
                  >
                    <template #fallback> </template>
                  </login>
                </div>
                <!-- </div> -->

                <login v-if="listing.current_price">
                  <template #fallback="{ loginURI, register }">
                    <p class="mt-4 text-lg">
                      <button
                        class="block w-full text-center bg-primary-btn-bg text-primary-btn-txt px-4 py-2 rounded-lg"
                        @click="register"
                      >
                        Register Now
                      </button>
                    </p>
                    <p class="mt-4 md:mt-2">
                      Already have an account?
                      <a :href="loginURI" class="text-boba-grey-dark font-bold"
                        >Log in</a
                      >
                    </p>
                  </template>
                  <template #default>
                    <div
                      class="flex items-stretch space-x-6 mt-4 md:mt-8 md:space-x-8"
                    >
                      <div v-if="listing.buy_limit">
                        <select
                          v-model="bidQuantity"
                          class="rounded-lg px-2 text-xl py-2.5"
                        >
                          <option value="0"></option>
                          <option
                            v-for="qty in Math.min(
                              listing.buy_limit,
                              listing.user_buy_limit ?? listing.buy_limit,
                            )"
                            :value="qty"
                          >
                            {{ qty }}
                          </option>
                        </select>
                        <p class="mt-1 text-xs text-right text-boba-grey-dark">
                          Limit
                          {{
                            Math.min(
                              listing.buy_limit,
                              listing.user_buy_limit ?? listing.buy_limit,
                            )
                          }}
                        </p>
                      </div>
                      <div
                        v-else
                        class="bg-white rounded-lg flex justify-center items-center"
                      >
                        <input
                          type="tel"
                          v-model="bidQuantity"
                          class="rounded-lg px-2 text-lg leading-none w-24 placeholder:font-light outline-none active:outline-none"
                          placeholder="Quantity"
                        />
                      </div>

                      <div class="flex-grow">
                        <button
                          class="text-xl text-center bg-boba-brown text-white rounded-lg py-2 px-8 bold w-full disabled:bg-boba-brown/40 disabled:text-boba-brown/50 transition-colors duration-200 ease-in-out"
                          @click="
                            onBid(listing.current_price.price, bidQuantity)
                          "
                          :disabled="!canBid(listing)"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                    <fade-transition>
                      <p
                        v-if="
                          !canBid(listing) &&
                          milesRequired(listing) > authStore.balance.available
                        "
                        class="text-red-700 text-sm text-right"
                      >
                        Not enough miles available
                      </p>
                    </fade-transition>
                  </template>
                </login>
                <div
                  v-else-if="
                    DateTime.fromISO(listing.starts_at) < DateTime.now()
                  "
                >
                  <p class="mt-4 text-xl text-left text-boba-grey-dark">
                    This item is no longer available.
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-8">
              <h3 class="text-2xl font-semibold">Description</h3>
              <div class="prose" v-html="listing.description"></div>
            </div>
          </div>
        </div>
      </template>
    </HeadlessListing>
  </AppLayout>
</template>
<script lang="ts" setup>
import { DateTime } from "ts-luxon";
import { ref } from "vue";
import { useListingStore } from "@/stores/listing";
import { Listing } from "@/types";
import { useRoute } from "vue-router";

import AppLayout from "@/components/Layouts/Application.vue";
import HeadlessListing from "@/components/Headless/Listing.vue";
import ScoreboardCountdown from "@/components/ScoreboardCountdown.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import Login from "@/pkg/auth/components/Login.vue";
import { useAuth } from "@/pkg/auth/store";

const authStore = useAuth();
const route = useRoute();

let currentImage = ref<boolean | string>(false);
let imageDetail = ref<string>("");
let bidQuantity = ref<number | null>(null);

const canBid = function (listing: Listing) {
  if (!listing) {
    return false;
  }

  if (
    bidQuantity.value === 0 ||
    bidQuantity.value === null ||
    bidQuantity.value < 1
  ) {
    return false;
  }

  if (String(bidQuantity.value).match(/\D/)) {
    return false;
  }

  if (authStore.balance.available < milesRequired(listing)) {
    return false;
  }

  if (listing.user_buy_limit <= 0) {
    return false;
  }

  return true;
};

function milesRequired(listing: Listing) {
  return bidQuantity.value * listing?.current_price?.price ?? 99;
}

function chooseImage(img: string) {
  currentImage.value = img;
}

function openDialog(src: string) {
  imageDetail.value = src;
  const dialog = document.querySelector("dialog");
  dialog?.showModal();
}

function closeDialog() {
  imageDetail.value = "";
  const dialog = document.querySelector("dialog");
  dialog?.close();
}

function callIfNotEnded(listing: Listing, reloadListing: () => void) {
  if (listing.auction_status != "ended") {
    reloadListing.call();
  }
}
</script>

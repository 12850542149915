<template>
  <component :is="layoutComponent">
    <slot>Layout Content</slot>
  </component>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import LayoutManager from "@/services/LayoutManager";

const layoutComponent = computed(() => {
  return LayoutManager.getLayout(window.location.hostname);
});
</script>

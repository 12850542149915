<template>
  <Popover class="relative">
    <PopoverButton
      class="flex pl-4 mx-4 my-2 md:mx-8 text-base overflow-hidden items-center bg-accent-300 text-primary-btn-bg md:bg-accent-300 rounded-full"
    >
      <p>{{ $format(Math.round(props.balance.available / 1e9)) }} miles</p>
      <div
        class="flex items-center rounded-full bg-accent-500 text-primary-btn-bg ml-2"
      >
        <p class="hidden md:block ml-4 mr-2">@{{ props.user.username }}</p>

        <Avatar
          :username="props.user.username"
          class="max-w-10 rounded-full bg-accent-400 border-2 border-accent-400"
        />
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <PopoverPanel
        class="absolute left-auto right-0 sm:right-6 mr-2 z-10 mt-2 flex max-w-[calc(100dvw-1rem)] w-screen sm:max-w-screen-xs max-h-[calc(100dvh-100px)] pt-0.5"
        v-slot="{ close }"
      >
        <div
          class="w-full flex-auto rounded-lg bg-gray-100 text-sm shadow-lg ring-1 ring-gray-900/5 leading-snug text-gray-900 pb-4 pr-2"
        >
          <PopoverButton
            class="absolute z-10 top-4 right-4 p-3 rounded-full flex justify-center items-center leading-none bg-gray-100 hover:bg-gray-200 transition-colors duration-300 ease-in-out w-12 h-12 sm:w-10 sm:h-10 aspect-square"
          >
            <span><i class="fa-solid fa-times fa-xl leading-none" /></span>
          </PopoverButton>

          <div class="slim overflow-y-auto h-full pr-2">
            <div class="text-center">
              <Avatar
                :username="props.user.username"
                size="256"
                class="w-1/4 bg-white mx-auto aspect-square object-cover rounded-full my-2 mt-8 border-2 border-boba-sand"
              >
                <div
                  class="absolute right-0 bottom-0 bg-white rounded-full w-6 h-6 border border-gray-300 flex justify-center items-center"
                >
                  <a
                    href="https://id.blokpax.com/profile"
                    title="Edit account avatar image"
                  >
                    <span class="text-xs text-gray-500 hover:text-gray-900">
                      <i class="fa-solid fa-pencil" />
                    </span>
                  </a>
                </div>
              </Avatar>

              <p class="text-boba-brown text-xl text-center">
                Hi, {{ props.user.username }}!
              </p>

              <div
                class="flex text-left w-full justify-start items-end flex-grow gap-x-1 pl-4 mt-6"
              >
                <div>
                  <p class="text-gray-600 text-left">Your Miles Balance</p>
                  <div class="flex items-center gap-x-1">
                    <div>
                      <CreditCardIcon class="w-6 h-6 text-gray-600" />
                    </div>
                    <p
                      class="tracking-tight --flex items-center gap-x-1 text-xl"
                    >
                      {{ $format(Math.round(props.balance.available / 1e9)) }}
                      Miles
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="ml-4 mb-4 relative mt-6">
              <NavMenuItems @close="close" />
            </div>
            <div
              class="ml-4 flex justify-center mt-6 gap-x-2 text-xs text-gray-500"
            >
              <p>
                <a href="/privacy" target="_blank" class="hover:text-gray-900"
                  >Privacy Policy</a
                >
              </p>
              <p>&middot;</p>
              <p>
                <a href="/terms" target="_blank" class="hover:text-gray-900"
                  >Terms of Service</a
                >
              </p>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
<script lang="ts" setup>
import { User } from "@/pkg/auth/types";
import Avatar from "@/pkg/auth/components/Avatar.vue";
import NavMenuItems from "@/components/NavMenuItems.vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import {
  ArrowRightStartOnRectangleIcon,
  ClockIcon,
  CreditCardIcon,
} from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps<{
  user: User;
  balance: { available: number };
}>();

const resources = [
  {
    name: "History",
    description: "View your order and/or bid history",
    href: router.resolve({
      name: "account-home",
    }).href,
    icon: ClockIcon,
  },
  {
    name: "Sign out",
    description: "Log out of your account",
    href: "/auth/logout",
    icon: ArrowRightStartOnRectangleIcon,
  },
];
</script>

<template>
  <AppLayout>
    <HeadlessBid
      :key="`listing-${route.params.slug}`"
      :listing-slug="route.params.slug"
      :bid-id="route.params.bid"
    >
      <template #fallback="{ login, register }">
        You must be logged in to view this page.
      </template>

      <template #default="{ listing }">
        <div class="flex">
          <div class="w-1/4 flex-grow-0 flex-shrink">
            <div
              class="bg-white rounded-lg overflow-none border-2 border-boba-grey flex justify-center items-center aspect-square"
            >
              <img :src="listing.images[0]" :alt="listing.images[0]" />
            </div>
            <div class="mt-6 flex justify-end items-center space-x-4">
              <p>
                <router-link
                  :to="{ name: 'account-home' }"
                  class="text-boba-grey-dark py-2 inline-flex space-x-1"
                >
                  <arrow-uturn-left-icon class="h-4 w-4 inline-block" />
                  <span>Back</span>
                </router-link>
              </p>

              <p>
                <router-link
                  :to="{ name: 'listing', params: { slug: listing.slug } }"
                  class="bg-boba-brown text-white px-6 py-2 rounded-lg shadow"
                >
                  View Listing
                </router-link>
              </p>
            </div>
          </div>

          <div class="tracking-tight ml-6 text-lg flex-grow">
            <p class="text-lg font-semibold leading-none">
              {{ listing.subtitle }}
            </p>
            <p class="text-3xl font-medium leading-none">{{ listing.title }}</p>

            <div class="mt-6">
              <p class="text-2xl font-semibold">Bid History</p>
              <div class="bg-gray-50 rounded-2xl pb-6 mt-2">
                <table class="w-full">
                  <thead>
                    <tr class="border-b-2 border-boba-brown">
                      <th
                        class="font-medium bg-boba-grey text-left px-4 py-2 rounded-tl-xl"
                      >
                        Timestamp
                      </th>
                      <th class="font-medium bg-boba-grey text-right">
                        Price (miles/ea)
                      </th>
                      <th class="font-medium bg-boba-grey text-right">
                        Quantity
                      </th>
                      <th class="font-medium bg-boba-grey text-right">Total</th>
                      <th class="font-medium bg-boba-grey rounded-tr-xl">
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="bid in listing.bids"
                      :key="`bid-${bid.id}`"
                      :id="bid.id"
                      class="even:bg-boba-grey/10"
                    >
                      <td class="py-2 px-4">
                        {{
                          DateTime.fromISO(bid.created_at).toFormat(
                            "MMMM d, yyyy @ h:mm a ZZZZ",
                          )
                        }}
                      </td>
                      <td class="py-2 text-right">
                        {{ $format(bid.bid_amount / 1e9) }} miles
                      </td>
                      <td class="py-2 text-right">
                        {{ $format(bid.bid_quantity)
                        }}<span
                          class="font-light text-sm text-boba-grey-dark ml-0.5"
                          >x</span
                        >
                      </td>
                      <td class="py-2 text-right">
                        {{ $format((bid.bid_amount * bid.bid_quantity) / 1e9) }}
                        miles
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </HeadlessBid>
  </AppLayout>
</template>
<script lang="ts" setup>
import AppLayout from "@/components/Layouts/Application.vue";
import HeadlessBid from "@/components/Headless/Bid.vue";
import { useRoute } from "vue-router";
import { DateTime } from "luxon";
import { ArrowUturnLeftIcon } from "@heroicons/vue/24/outline";
const route = useRoute();
</script>

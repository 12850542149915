<template>
  <div class="text-sm flex flex-col min-h-screen">
    <section
      class="bg-boba-brown relative flex justify-between items-center text-white"
    >
      <div id="logo" class="h-12 fill-white ml-4 md:ml-8 py-1">
        <a href="https://blokpax.com">
          <blokpax-svg class="h-full fill-primary-btn-txt text-white" />
        </a>
      </div>

      <user-auth />
    </section>

    <div class="px-4 mt-4 md:px-8">
      <slot name="default" />
    </div>

    <div class="flex-grow flex-shrink"></div>

    <section
      class="bg-boba-brown text-white mt-20 pt-8 min-h-80 px-4 md:px-8 py-4"
    >
      <div class="text-right text-4xl my-2 space-x-4 md:space-x-4">
        <a
          href="https://www.instagram.com/blokpax/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow us on Instagram"
          ><i class="fa-brands fa-instagram"
        /></a>
        <a
          href="https://www.facebook.com/blokpax/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow us on Facebook"
          ><i class="fa-brands fa-facebook"
        /></a>
        <a
          href="https://www.youtube.com/@blokpax"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow us on Youtube"
          ><i class="fa-brands fa-youtube"
        /></a>
        <a
          href="https://x.com/blokpax"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow us on X"
          ><i class="fa-brands fa-x-twitter"
        /></a>
      </div>

      <div
        class="h-1 bg-gradient-to-r from-accent to-accent via-primary-btn-bg rounded-sm relative"
      ></div>

      <div
        class="ml-36 md:flex md:justify-between mt-2 md:ml-0 text-right md:text-left"
      >
        <div
          class="md:space-x-1 md:space-y-0 flex flex-col md:block space-y-2 space-x-0"
        >
          <a
            href="//market.blokpax.com/terms"
            target="_blank"
            title="Terms of Service"
            >Terms of Service</a
          >
          <span class="hidden md:inline-block">&middot;</span>

          <a
            href="//market.blokpax.com/privacy"
            target="_blank"
            class="no-brand"
            title="Privacy Policy"
          >
            Privacy Policy</a
          >
          <span class="hidden md:inline-block">&middot;</span>

          <a
            href="//market.blokpax.com/cookie-policy"
            target="_blank"
            class="no-brand"
            title="Cookie Policy"
            >Cookie Policy</a
          >
        </div>

        <div class="text-right md:mt-0 mt-4">
          <p>&copy;{{ year }} Blokpax</p>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import "@/assets/styles/blokpax.css";
import UserAuth from "@/components/UserAuth.vue";
import BlokpaxSvg from "@/assets/svg/blokpax.svg";

const year = new Date().getFullYear();
</script>
